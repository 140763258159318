import MediaDetails from "./MediaDetails";
import Checkout from "./Checkout";
import OrderDetails from "./OrderDetails";
import Search from "./Search";

export default {
  MediaDetails,
  Checkout,
  OrderDetails,
  Search
};
